//Standard Viewport Widths
$phone: 640px;
$tablet: 768px;
$desktop: 1024px;
$large-desktop: 1366px;

//Colors
$deep-pink: #ff1493;
$white: #fff;
$black: #333;
$dark-grey: #424949;
$light-grey: #f2f3f4;
