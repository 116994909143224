.contact {
  display: flex;
  padding: 0.5em 1.25em;
  padding-bottom: 15px;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  .one-contact {
    display: flex;
    width: 50%;
    max-width: 300px;
    padding: 1em;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 1em;
    .contact-details {
      div {
        display: flex;
        align-items: center;
        div {
          .contact-icon {
            margin: 0 10px;
            margin-right: 20px;
            margin-bottom: -3px;
          }
        }
      }
      .map {
        position: relative;
        overflow: hidden;
        padding-top: 50%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .two-contact {
    display: flex;
    width: 50%;
    padding: 1em;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    form {
      background: #f9f9f9;
      width: 100%;
      max-width: 400px;
      padding: 2em;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="url"],
      textarea {
        width: 100%;
        border: 1px solid #ccc;
        background: #fff;
        margin: 0 0 5px;
        padding: 1em;
        &:hover {
          -webkit-transition: border-color 0.5s ease-in-out;
          -moz-transition: border-color 0.5s ease-in-out;
          transition: border-color 0.5s ease-in-out;
          border: 1px solid #777;
        }
      }
      textarea {
        height: 200px;
        max-width: 100%;
        resize: none;
      }
      button[type="submit"] {
        cursor: pointer;
        width: 100%;
        border: none;
        background: $deep-pink;
        color: #fff;
        margin: 0 0 5px;
        padding: 10px;
        font-size: 15px;
        text-transform: uppercase;
      }
    }
  }
}

@media all and (max-width: $desktop) {
  .contact {
    flex-direction: column;
    .one-contact,
    .two-contact {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
}
